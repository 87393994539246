//import { createRouter, createWebHashHistory } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'

import LoginView from '../views/LoginView.vue'

const routes = [
    {
        path: '/',
        name: 'login',
        component: LoginView
    },
    {
        path: '/recordList',
        name: 'recordList',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/RecordView.vue')
    },
    {
        path: '/recordReg',
        name: 'recordReg',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/RecordRegView.vue')
    },
    {
        path: '/video',
        name: 'video',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/VideoView.vue')
    },
    {
        path: '/adminOnly',
        name: 'adminOnly',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AdminView.vue')
    },
    {
        path: '/videoPop/:gameId/:viewSc',
        name: 'videoPop',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../components/Video/VideoPopup.vue')
    },
    {
        path: '/videoEditPop/:gameId/:viewSc',
        name: 'videoEditPop',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../components/Video/VideoEditPopup.vue')
    },
    // 404 page
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import('../components/NotFound.vue'),
    },
]

// const router = createRouter({
//   history: createWebHashHistory(),
//   routes,
//   mode: 'hash'
// })

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode: 'history' 
})


export default router
