<template>
  <div id="wrap" class="wrap login">
    <!-- container -->
    <div class="container">
      <div class="contents">
        <div class="login-wrap">
          <img src="@/assets/images/common/icon_login.png" alt="KBSA" />
          <div class="login-box">
            <p class="txt">LOGIN</p>
            <ul class="login-input">
              <li class="id">
                <input type="text" id="id" placeholder="ID" v-model="id" />
              </li>
              <li class="pw">
                <input type="password" id="pw" placeholder="PASSWORD" v-model="password" @keyup.enter="btnLogin()" />
              </li>
            </ul>
            <a href="#" class="btn-login" @click.prevent="btnLogin()">로그인</a>
            <p class="id-save">
              <input type="checkbox" id="id_save" v-model="idSave" />
              <label for="id_save">아이디 저장</label>
            </p>
          </div>
        </div>
      </div>

    </div>
    <!-- //container -->
  </div>
</template>

<script>
import "@/assets/css/reset.css"
import "@/assets/css/common.css"
import "@/assets/css/font.css"
import "@/assets/css/layout.css"
import "@/assets/css/contents.css"

import MemberService from "@/utils/service/memberService.js"

export default {
  name: 'LoginView',
  components: {},
  data() {
    return {
      memberService: new MemberService(),
      id: '',         // 아이디
      password: '',   // 비밀번호
      isSave: false   // 아이디 저장
    };
  },
  setup() { },
  created() { },
  mounted() { 
    this.id = window.$cookies.get("KBO_KBSA_ID"),
		this.idSave = window.$cookies.get("KBO_KBSA_ID") == undefined ? false : true
    sessionStorage.clear(); // 로그인 페이지 세션스토리지 삭제
  },
  unmounted() { },
  methods: {
    async btnLogin() {
      const result = await this.memberService.$login(this.id, this.password);
      if(result.data.code == "100"){
        
        // 아이디 저장 쿠키
				if(this.idSave == true){
					this.$cookies.set("KBO_KBSA_ID", this.id, "7d");
				} else {
					this.$cookies.remove("KBO_KBSA_ID");
				}

				sessionStorage.setItem("user_sc", JSON.parse(result.data.userInfo).U_SC);
				sessionStorage.setItem("user_id", JSON.parse(result.data.userInfo).U_ID);
				this.$router.push('/recordList');
      } else {
        alert("아이디와 비밀번호를 확인해 주세요.");
      }
    },
  }
}
</script>
