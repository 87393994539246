import axios from 'axios';

class MemberService {
  // 로그인
  async $login(id, password) {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("password", password);
    const result = axios.post("/Member/login", formData);

    return await result;
  }

  // 로그아웃
  async $logout() {
    axios.get("/Member/logout");
  }

  // 비밀번호 변경
  async $changePass(id, nowPass, newPass){
    const passData = new FormData();
    passData.append("id", id);
    passData.append("nowPass", nowPass);
    passData.append("newPass", newPass);

    let result = axios.post("/Member/changePassword", passData);

    return await result;
  }
}

export default MemberService